/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/saga-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";

*{
    font-size: 14px;
}

body {
    padding: 0;
    margin: 0;
}

.power-bi-client{
    height: 100vh;
}